// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hr_blockHr__TQb9F {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 20px;\n}\n\n\n\n.Hr_titleHr__faJyC {\n    font-family: \"Poppins\";\n    font-style: normal;\n    font-weight: 600;\n    font-size: 50px;\n    line-height: 104.5%; \n    color: #ffffff;\n    margin: 0;\n    white-space: nowrap;\n}\n\n.Hr_hr__JKO76 {\n    width: 100%;\n    border: 2px solid rgba(255, 255, 255, 0.6);\n    margin: 0;\n    margin-top: 10px;  \n\n}\n\n\n@media screen and (max-width: 480px) {\n    .Hr_blockHr__TQb9F {\n        flex-direction: column;\n    }\n\n    .Hr_titleHr__faJyC {\n        white-space: pre-wrap;\n        text-align: center;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/HomePage/Hr/Hr.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;AACb;;;;AAIA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,0CAA0C;IAC1C,SAAS;IACT,gBAAgB;;AAEpB;;;AAGA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,qBAAqB;QACrB,kBAAkB;IACtB;AACJ","sourcesContent":[".blockHr {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 20px;\n}\n\n\n\n.titleHr {\n    font-family: \"Poppins\";\n    font-style: normal;\n    font-weight: 600;\n    font-size: 50px;\n    line-height: 104.5%; \n    color: #ffffff;\n    margin: 0;\n    white-space: nowrap;\n}\n\n.hr {\n    width: 100%;\n    border: 2px solid rgba(255, 255, 255, 0.6);\n    margin: 0;\n    margin-top: 10px;  \n\n}\n\n\n@media screen and (max-width: 480px) {\n    .blockHr {\n        flex-direction: column;\n    }\n\n    .titleHr {\n        white-space: pre-wrap;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockHr": "Hr_blockHr__TQb9F",
	"titleHr": "Hr_titleHr__faJyC",
	"hr": "Hr_hr__JKO76"
};
export default ___CSS_LOADER_EXPORT___;
