// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Delivery_delivery__yUNDW {\n    padding: 100px 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 200px;\n    margin: 0;\n    margin-bottom: 100px;\n}\n\n@media screen and (max-width: 1400px) {\n   .Delivery_delivery__yUNDW {\n    flex-wrap: wrap;\n    gap: 150px;\n    \n   }\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/HomePage/Delivery/Delivery.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,SAAS;IACT,oBAAoB;AACxB;;AAEA;GACG;IACC,eAAe;IACf,UAAU;;GAEX;EACD","sourcesContent":[".delivery {\n    padding: 100px 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 200px;\n    margin: 0;\n    margin-bottom: 100px;\n}\n\n@media screen and (max-width: 1400px) {\n   .delivery {\n    flex-wrap: wrap;\n    gap: 150px;\n    \n   }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delivery": "Delivery_delivery__yUNDW"
};
export default ___CSS_LOADER_EXPORT___;
