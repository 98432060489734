import React from 'react'
import './Konfid.scss'
import ScrollToTop from '../ScrollToTop'

export const Konfid = () => {
    return (
        <section className="section__oplata">
            <div className='container'>
                <ScrollToTop />
                <p className='oplata__title'>
                    Политика конфиденциальности
                </p>


                <p className='oplata__text'>

                    <p className='oplata__gl'>
                        1. Определение терминов
                    </p>

                    В настоящей Политике конфиденциальности используются следующие термины:
                    «Администрация сайта» (далее – Администрация) – уполномоченные сотрудники на управление сайтом frantsuz-shop, действующие от имени ИП Авалян. В. Г., которые организуют и (или) осуществляют обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                    «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).
                    «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                    «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
                    «Сайт frantsuz-shop» - это совокупность связанных между собой веб-страниц, размещенных в сети Интернет по уникальному адресу (URL): https://http://127.0.0.1:8000/api/, а также его субдоменах.
                    «Субдомены» - это страницы или совокупность страниц, расположенные на доменах третьего уровня, принадлежащие сайту frantsuz-shop, а также другие временные страницы, внизу который указана контактная информация Администрации
                    «Пользователь сайта frantsuz-shop» (далее Пользователь) – лицо, имеющее доступ к сайту frantsuz-shop, посредством сети Интернет и использующее информацию, материалы и продукты сайта frantsuz-shop.
                    «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
                    «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, через который Пользователь получает доступ на Сервис доставки еды.
                    «Товар » - продукт, который Пользователь заказывает на сайте и оплачивает через платёжные системы.

                    <p className="oplata__subtitle">
                        2. Общие положения
                    </p>

                    Использование сайта frantsuz-shop Пользователем означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
                    В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта frantsuz-shop.
                    Настоящая Политика конфиденциальности применяется к сайту frantsuz-shop. Сервис доставки еды не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте frantsuz-shop.
                    Администрация не проверяет достоверность персональных данных, предоставляемых Пользователем.

                    <p className='oplata__subtitle'>
                        3. Предмет политики конфиденциальности
                    </p>

                    Настоящая Политика конфиденциальности устанавливает обязательства Администрации по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации при регистрации на сайте frantsuz-shop, при подписке на информационную e-mail рассылку или при оформлении заказа.
                    Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения форм на сайте frantsuz-shop и включают в себя следующую информацию:
                    фамилию, имя, отчество Пользователя;
                    контактный телефон Пользователя;
                    адрес электронной почты (e-mail)
                    место жительство Пользователя (при необходимости)
                    адрес доставки Товара (при необходимости) 3.2.6. фотографию (при необходимости).
                    Сервис доставки еды защищает Данные, которые автоматически передаются при посещении страниц: - IP адрес; - информация из cookies; - информация о браузере - время доступа; - реферер (адрес предыдущей страницы).

                    Отключение cookies может повлечь невозможность доступа к частям сайта , требующим авторизации.
                    Сервис доставки еды осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью предотвращения, выявления и решения технических проблем.
                    Любая иная персональная информация неоговоренная выше (история посещения, используемые браузеры, операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.

                    <p className='oplata__subtitle'>
                        4. Цели сбора персональной информации пользователя
                    </p>
                    Персональные данные Пользователя Администрация может использовать в целях:
                    Идентификации Пользователя, зарегистрированного на сайте frantsuz-shop для его дальнейшей авторизации, оформления заказа и других действий.
                    Предоставления Пользователю доступа к персонализированным данным сайта frantsuz-shop.
                    Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта frantsuz-shop, оказания услуг и обработки запросов и заявок от Пользователя.
                    Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
                    Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
                    Создания учетной записи для использования частей сайта frantsuz-shop, если Пользователь дал согласие на создание учетной записи.
                    Уведомления Пользователя по электронной почте.
                    Предоставления Пользователю эффективной технической поддержки при возникновении проблем, связанных с использованием сайта frantsuz-shop.
                    Предоставления Пользователю с его согласия специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени сайта frantsuz-shop.
                    Осуществления рекламной деятельности с согласия Пользователя.

                    <p className='oplata__subtitle'>
                        5. Способы и сроки обработки персональной информации
                    </p>

                    Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
                    Пользователь соглашается с тем, что Администрация вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи (в том числе электронной), операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на сайте frantsuz-shop, включая доставку Товара, документации или e-mail сообщений.
                    Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                    Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                    При утрате или разглашении персональных данных Администрация вправе не информировать Пользователя об утрате или разглашении персональных данных.
                    Администрация принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                    Администрация совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.

                    <p className='oplata__subtitle'>
                        6. Права и обязанности сторон
                    </p>

                    Пользователь вправе:
                    Принимать свободное решение о предоставлении своих персональных данных, необходимых для использования сайта frantsuz-shop, и давать согласие на их обработку.
                    Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.

                    Пользователь имеет право на получение у Администрации информации, касающейся обработки его персональных данных, если такое право не ограничено в соответствии с федеральными законами. Пользователь вправе требовать от Администрации уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
                    Администрация обязана:
                    Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
                    Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2 и 5.3. настоящей Политики Конфиденциальности.
                    Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
                    Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.

                    <p className='oplata__subtitle'>
                        7. Ответственность сторон
                    </p>


                    Администрация, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
                    В случае утраты или разглашения Конфиденциальной информации Администрация не несёт ответственность, если данная конфиденциальная информация:
                    Стала публичным достоянием до её утраты или разглашения.
                    Была получена от третьей стороны до момента её получения Администрацией Ресурса.
                    Была разглашена с согласия Пользователя.
                    Пользователь несет полную ответственность за соблюдение требований законодательства РФ, в том числе законов о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за содержание и форму материалов.
                    Пользователь признает, что ответственность за любую информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ как к части сайта frantsuz-shop, несет лицо, предоставившее такую информацию.
                    Пользователь соглашается, что информация, предоставленная ему как часть сайта frantsuz-shop, может являться объектом интеллектуальной собственности, права на который защищены и принадлежат другим Пользователям, партнерам или рекламодателям, которые размещают такую информацию на сайте frantsuz-shop. Пользователь не вправе вносить изменения, передавать в аренду, передавать на условиях займа, продавать, распространять или создавать производные работы на основе такого Содержания (полностью или в части), за исключением случаев, когда такие действия были письменно прямо разрешены собственниками такого Содержания в соответствии с условиями отдельного соглашения.
                    В отношение текстовых материалов (статей, публикаций, находящихся в свободном публичном доступе на сайте frantsuz-shop) допускается их распространение при условии, что будет дана ссылка на Сервис доставки еды.
                    Администрация не несет ответственности перед Пользователем за любой убыток или ущерб, понесенный Пользователем в результате удаления, сбоя или невозможности сохранения какого-либо Содержания и иных коммуникационных данных, содержащихся на сайте frantsuz-shop или передаваемых через него.

                    Администрация не несет ответственности за любые прямые или косвенные убытки, произошедшие из-за: использования либо невозможности использования сайта, либо отдельных сервисов; несанкционированного доступа к коммуникациям Пользователя; заявления или поведение любого третьего лица на сайте.
                    Администрация не несет ответственность за какую-либо информацию, размещенную пользователем на сайте frantsuz-shop, включая, но не ограничиваясь: информацию, защищенную авторским правом, без прямого согласия владельца авторского права.

                    <p className='oplata__subtitle'>
                        8. Разрешение споров
                    </p>

                    До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администрацией, обязательным является предъявление претензии (письменного предложения или предложения в электронном виде о добровольном урегулировании спора).
                    Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно или в электронном виде уведомляет заявителя претензии о результатах рассмотрения претензии.
                    При не достижении соглашения спор будет передан на рассмотрение Арбитражного суда г. Москва.
                    К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией применяется действующее законодательство Российской Федерации.

                    <p className='oplata__subtitle'>
                        9. Дополнительные условия
                    </p>

                    Администрация вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя. Новая Политика конфиденциальности вступает в силу с момента ее размещения на сайте frantsuz-shop, если иное не предусмотрено новой редакцией Политики конфиденциальности. Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать по адресу: rating@frantsuz.ru Действующая Политика конфиденциальности размещена на странице по адресу: https://http://127.0.1:8000/api/policy

                </p>

            </div>
        </section>
    )
}
