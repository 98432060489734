// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hits_cards__FV-kS {\n    display: flex;\n    justify-content: center;\n   \n    margin: 0;\n}\n\n@media screen and (max-width: 1480px) {\n    .Hits_cards__FV-kS {\n        flex-wrap: wrap;\n    }\n}\n\n.Hits_container__QL5Qo{\n    max-width: 1820px;\n    display: flex;\n    flex-wrap: wrap;\n    padding-top: 40px;\n    justify-content: center;\n    gap: 40px;\n    margin: 0 auto;\n    \n}", "",{"version":3,"sources":["webpack://./src/components/HomePage/SalesHits/Hits.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;;IAEvB,SAAS;AACb;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,SAAS;IACT,cAAc;;AAElB","sourcesContent":[".cards {\n    display: flex;\n    justify-content: center;\n   \n    margin: 0;\n}\n\n@media screen and (max-width: 1480px) {\n    .cards {\n        flex-wrap: wrap;\n    }\n}\n\n.container{\n    max-width: 1820px;\n    display: flex;\n    flex-wrap: wrap;\n    padding-top: 40px;\n    justify-content: center;\n    gap: 40px;\n    margin: 0 auto;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cards": "Hits_cards__FV-kS",
	"container": "Hits_container__QL5Qo"
};
export default ___CSS_LOADER_EXPORT___;
