// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KatalogsAccsessuars_katalogs__9HwOM {\n  display: flex;\n  gap: 122px;\n  padding: 100px 0;\n  margin: 0;\n  justify-content: flex-start;\n  color: white;\n  width: 100%;\n  margin: 0;\n  box-sizing: border-box;\n}\n@media screen and (max-width: 1070px) {\n  .KatalogsAccsessuars_katalogs__9HwOM {\n    flex-direction: column;\n  }\n}\n.KatalogsAccsessuars_katalogs__9HwOM .KatalogsAccsessuars_filter__tOLyJ {\n  margin: 0;\n  box-sizing: border-box;\n}\n.KatalogsAccsessuars_katalogs__9HwOM .KatalogsAccsessuars_kiys__5\\+\\+Qh {\n  max-width: 1190px;\n  margin: 0;\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/components/KatalogsAccessuar/KatalogsAccsessuars.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,UAAA;EACA,gBAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,sBAAA;AACJ;AACI;EAXJ;IAYQ,sBAAA;EAEN;AACF;AAAI;EACI,SAAA;EACA,sBAAA;AAER;AACI;EACI,iBAAA;EACA,SAAA;EACA,sBAAA;AACR","sourcesContent":[".katalogs {\n    display: flex;\n    gap: 122px;\n    padding: 100px 0;\n    margin: 0;\n    justify-content: flex-start;\n    color: white;\n    width: 100%;\n    margin: 0;\n    box-sizing: border-box;\n\n    @media screen and (max-width: 1070px) {\n        flex-direction: column;\n    }\n\n    & .filter {\n        margin: 0;\n        box-sizing: border-box;\n    }\n\n    & .kiys {\n        max-width: 1190px;\n        margin: 0;\n        box-sizing: border-box;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"katalogs": "KatalogsAccsessuars_katalogs__9HwOM",
	"filter": "KatalogsAccsessuars_filter__tOLyJ",
	"kiys": "KatalogsAccsessuars_kiys__5++Qh"
};
export default ___CSS_LOADER_EXPORT___;
