// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stocks_cards__Oa-11 {\n    display: flex;\n    gap: 40px;\n    justify-content: center;\n    margin: 0;\n    padding: 70px 0;\n    margin-bottom: 100px;\n    flex-wrap: wrap;\n}\n\n@media screen and (max-width: 1480px) {\n    .Stocks_cards__Oa-11 {\n        flex-wrap: wrap;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/HomePage/Stocks/Stocks.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,SAAS;IACT,eAAe;IACf,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".cards {\n    display: flex;\n    gap: 40px;\n    justify-content: center;\n    margin: 0;\n    padding: 70px 0;\n    margin-bottom: 100px;\n    flex-wrap: wrap;\n}\n\n@media screen and (max-width: 1480px) {\n    .cards {\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cards": "Stocks_cards__Oa-11"
};
export default ___CSS_LOADER_EXPORT___;
