// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/Fonts/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/Fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/Fonts/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Poppins\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"ttf\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"ttf\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"ttf\");\n}\n\nbody * {\n  margin: 0 auto;\n}\n\nbody {\n  margin: 0;\n  background: #000000;\n}\n.container {\n  max-width: 1820px;\n  width: 100%;\n  padding: 0px 20px;\n  margin: 0 auto;\n  box-sizing: border-box;\n}\n\n@media screen and (max-width: 1200px) {\n  .container {\n    max-width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB;;yDAEsD;AACxD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["@font-face {\n  font-family: \"Poppins\";\n  src: url(\"/src/Fonts/Poppins-Medium.ttf\") format(\"ttf\"),\n    url(\"/src/Fonts/Poppins-Regular.ttf\") format(\"ttf\"),\n    url(\"/src/Fonts/Poppins-SemiBold.ttf\") format(\"ttf\");\n}\n\nbody * {\n  margin: 0 auto;\n}\n\nbody {\n  margin: 0;\n  background: #000000;\n}\n.container {\n  max-width: 1820px;\n  width: 100%;\n  padding: 0px 20px;\n  margin: 0 auto;\n  box-sizing: border-box;\n}\n\n@media screen and (max-width: 1200px) {\n  .container {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
