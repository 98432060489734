// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Katalogs_katalogs__yAozr {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 80px 0;\n    gap: 80px;\n    margin-bottom: 100px;\n}\n\n@media screen and (max-width: 1650px) {\n    .Katalogs_katalogs__yAozr {\n        flex-wrap: wrap;\n        justify-content: flex-start;\n    }\n\n    \n}\n", "",{"version":3,"sources":["webpack://./src/components/HomePage/KatalogMini/Katalogs.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI;QACI,eAAe;QACf,2BAA2B;IAC/B;;;AAGJ","sourcesContent":[".katalogs {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 80px 0;\n    gap: 80px;\n    margin-bottom: 100px;\n}\n\n@media screen and (max-width: 1650px) {\n    .katalogs {\n        flex-wrap: wrap;\n        justify-content: flex-start;\n    }\n\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"katalogs": "Katalogs_katalogs__yAozr"
};
export default ___CSS_LOADER_EXPORT___;
